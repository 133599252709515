import Vue from 'vue'
import LC from 'leancloud-storage'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element'

Vue.config.productionTip = false

LC.init({
  appId: 'PEWoRgMSXeYXBGYCvr6Hv1am-MdYXbMMI', 
  appKey: 'SrD4mcJXdccE2687KVyWrseA', 
  serverURLs: 'https://peworgms.api.lncldglobal.com'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
