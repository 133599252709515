


















import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import LC from 'leancloud-storage';
import { Loading } from 'element-ui';
import shortUUID from 'short-uuid';

@Component({
  components: {},
})
export default class Home extends Vue {
  private originUrl: string = '';
  private newUrl: string = '';

  private submit() {
    let loadingInstance = Loading.service({});
    const uuid = shortUUID().new();

    const LCShortUrl = LC.Object.extend('shortUrl');
    const lcShortUrl = new LCShortUrl();
    lcShortUrl.set('uuid', uuid);
    lcShortUrl.set('url', this.originUrl);

    let getAcl = () => {
      let acl = new LC.ACL();
      acl.setPublicReadAccess(!0);
      acl.setPublicWriteAccess(!1);
      return acl;
    };

    lcShortUrl.setACL(getAcl());

    lcShortUrl.save().then((res) => {
      console.log('成功：', res);
      console.log('shortUrl:', uuid);
      this.newUrl =
        'https://short.landandan.cn/' + _.get(res, 'attributes.uuid', '');
      loadingInstance.close();
    });
  }
}
